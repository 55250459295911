.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.planby {
  box-shadow: 0px 10px 40px -15px rgba(10, 20, 80, 0.4);
  padding: 0px !important;
  background: #ffffff;
}
@media only screen and (max-width: 575px) {
  .planby {
    border-radius: 0px !important;
  }
}
@media only screen and (min-width: 576px) {
  .planby {
    padding: 5px !important;
    /* padding-top: 0px !important; */
    border-radius: 15px !important;
  }
}

.planby > div {
  overflow: visible;
}
.planby.small > div > div:first-of-type {
  background: transparent;
}
.planby.small [class*="ScrollBox"] > [class*="Box"] {
  top: 40px;
}

/* Layout */
.planby-layout {
  z-index: 999;
}
@media only screen and (max-width: 575px) {
  .planby-layout {
    border-radius: 0px !important;
  }
}
@media only screen and (min-width: 576px) {
  .planby-layout {
    border-radius: 15px !important;
  }
}

/* Line */

@media only screen and (max-width: 575px) {
  .planby .planby-line {
    min-height: calc(100vh + 80px) !important;
  }
}

/* Current time */
.planby .planby-current-time {
  top: 40px;
}
.planby .planby-current-content {
}

/* Channels */
.planby .planby-channels {
  margin-top: 10px !important;
  margin-bottom: 35px !important;
}

.planby .planby-channels .aircrew {
  margin-top: 20px;
}

/* Channel */
.planby .planby-channel {
}

/* Program */

@media only screen and (max-width: 575px) {
  .planby .planby-corner-box {
    border-radius: 0px !important;
  }
  .planby .planby-corner-box {
    height: 70px !important;
  }
}
@media only screen and (min-width: 576px) {
  .planby .planby-corner-box {
    border-radius: 16px 0px 0px 0px !important;
  }
  .planby .planby-corner-box {
    height: 74px !important;
  }
}

.planby .planby-content {
  margin-top: 10px !important;
  margin-bottom: 35px !important;
}
.planby .planby-program-box {
  /* width: 100px !important; */
  overflow: visible;
}
.planby .planby-program-box.booking {
  z-index: 3;
}
.planby .planby-program-box.aircrew {
  margin-top: 20px;
}
.planby .planby-program-content {
  padding: 6px 15px;
  border-radius: 10px;
  border: 1px solid #eef5f3;
  font-size: 14px;
  background: linear-gradient(to right, #fafcff, #f7f9fb);
}
.planby .planby-program-content.small {
  padding: 5px 5px;
  border-radius: 4px;
}
.planby .planby-program-content.medium {
  padding: 5px 8px;
  border-radius: 8px;
}
.planby .planby-program-content.booking {
  background: linear-gradient(to right, #235f88, #195178);
  border: none;
  transition: all ease 150ms;
  z-index: 2;
}
.planby .planby-program-content.booking.pending {
  background: linear-gradient(to right, #fff93a, #ffeb40);
  border: none;
  transition: all ease 150ms;
  z-index: 2;
}
.planby .planby-program-content.booking.approved {
  background: linear-gradient(to right, #235f88, #195178);
  border: none;
  transition: all ease 150ms;
  z-index: 2;
}
.planby .planby-program-content.booking.completed {
  background: linear-gradient(to right, #a7e3f5, #70d3f1);
  border: none;
  transition: all ease 150ms;
  z-index: 2;
}
.planby .planby-program-content.booking.draft {
  background: linear-gradient(to right, #cfdde5, #bcd4e5);
  border: none;
  transition: all ease 150ms;
  z-index: 2;
}
.planby .planby-program-content:hover {
  background: linear-gradient(to right, #f2f4f9, #e9edf3);
}
.planby .planby-program-content.booking:hover {
  background: linear-gradient(to right, #13486d, #0b3653);
  box-shadow: 0px 2px 11px -3px rgba(10, 20, 50, 0.5);
  transform: translateY(-2px) scale(1.01);
}

.planby .planby-program-content.booking.pending:hover {
  background: linear-gradient(to right, #fff832, #ffe400);
  transition: all ease 200ms;
  z-index: 2;
}
.planby .planby-program-content.booking.completed:hover {
  background: linear-gradient(to right, #98e4fb, #5dc6e5);
  transition: all ease 200ms;
  z-index: 2;
}
.planby .planby-program-content.booking.draft:hover {
  background: linear-gradient(to right, #b2c8d5, #89abc3);
  transition: all ease 200ms;
  z-index: 2;
}
.planby .planby-program-content.unavailable {
  background: #fff0f0;
  transition: all ease 200ms;
  z-index: 1;
}
.planby .planby-program-content.booking.tempUnavailable {
  background: #fff0f0;
  transition: all ease 200ms;
  z-index: 1;
  cursor: not-allowed;
  pointer-events: none;
}
.planby .planby-program-content.booking.tempUnavailable:hover {
  background: #fff0f0;
  box-shadow: none;
  transform: translateY(0) scale(0);
  z-index: 1;
}

.planby .planby-program-flex {
}
.planby .planby-program-stack {
}
.planby .planby-program-title {
}
.planby .planby-program-title {
  color: #eeeeee;
  font-weight: 600;
}
.planby .planby-program-title.booking {
  color: #ffffff;
}
.planby .planby-program-content.small .planby-program-title.booking {
  font-size: 9px !important;
}
.planby .planby-program-text {
  color: #b1c1d8;
  font-weight: 300;
  /* white-space: pre-wrap !important; */
}
.planby .planby-program-content.booking .planby-program-text {
  color: #b1c1d8;
  font-weight: 500;
}

.planby .planby-program-content.booking.pending .planby-program-text {
  color: #9dabc0;
  font-weight: 500;
}
.planby .planby-program-content.booking.draft .planby-program-text,
.planby .planby-program-content.booking.completed .planby-program-text {
  color: #ffffff;
  font-weight: 500;
}
.planby .planby-program-content.booking.pending .planby-program-title,
.planby .planby-program-content.booking.draft .planby-program-title,
.planby .planby-program-content.booking.completed .planby-program-title {
  color: #444444;
}

.planby .planby-program-content.booking.tempUnavailable .planby-program-text {
  color: #9dabc0;
}
.planby .planby-program-content.booking.tempUnavailable .planby-program-title {
  color: #888888;
}

.planby ::-webkit-scrollbar-thumb:hover {
  background-color: #ced5df !important;
}

.planby #planby-layout-scrollbox.planby-layout {
  overscroll-behavior: none !important;
}

/* Timeline */
.planby .planby-timeline-wrapper {
}
.planby-timeline-box {
  font-size: 8px;
}
.planby .planby-timeline-time {
}
.planby .planby-timeline-dividers {
}
.planby .planby-timeline-wrapper {
  height: 70px;
}
.planby .planby-timeline-week-month-date {
  line-height: 1.3;
}
.planby .planby-timeline-week-month-date span {
  font-weight: 500;
  font-size: 0.95rem;
}
.planby .planby-timeline-week-month-date span:first-of-type {
  font-weight: 500;
  color: #38b2ac;
  font-size: 0.75rem;
}

.planby .planby-timeline-box {
  height: 50px;
  top: 0px;
}

.planby.day .planby-timeline-box {
  top: -5px;
}

.planby .planby-timeline-box:not(:last-of-type):after {
  right: -1px;
}
.planby .planby-current-content {
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  background-color: #52c7d7;
  color: white;
  top: 3px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  left: -30px;
}

.auth-table {
  border-collapse: separate !important;
  border-spacing: 0 10px;
}

th,
td {
  width: 100px;
  text-align: center;
  border: 1px solid;
  padding: 0px;
}
th:first-child,
td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 75px;
  max-width: 75px;
}
th:last-child,
td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 125px;
  max-width: 125px;
}
.tr:first-child {
  margin-top: 20px;
}

/* Time Picker */

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #00d0ed !important;
}

.form-control.form-control-sm.flatpickr-input:not(:disabled):not(.disabled) {
  background-color: #fff !important;
  -webkit-box-shadow: inset 0 0 0px 20px #fff;
  -moz-box-shadow: inset 0 0 0px 20px #fff;
  box-shadow: inset 0 0 0px 20px #fff;
}
.form-control.form-control-sm.flatpickr-input.disabled {
  cursor: text;
}
.flatpickr-day.selected {
  background: #00d0ed !important;
  border-color: #00d0ed !important;
}
.form-check-input {
  margin-top: 0.4rem;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid #ced4da;
  /* border-radius: 5px; */
  -webkit-box-shadow: 0 7px 25px -12px #444;
  -moz-box-shadow: 0 7px 25px -12px #444;
  box-shadow: 0 7px 25px -12px #444;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #efefef !important;
}

#advisoryForm .form-group .col-sm-3.col-4 .btn-sm svg {
  vertical-align: initial;
}

.flatpickr {
  border-radius: 7px;
}

.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months {
  border: 1px solid transparent !important;
  margin-right: 5px;
  font-weight: 500;
}
.flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover {
  background: #ffffff;
  border: 1px solid #ddd !important;
}
.flatpickr-calendar .flatpickr-month,
.flatpickr-calendar .flatpickr-current-month {
  height: 35px;
}
.flatpickr-calendar .flatpickr-current-month {
  font-size: 120%;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6px 0 0 0;
  line-height: 1.4;
}
.flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  color: #444;
}

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #00d0ed !important;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month svg {
  vertical-align: initial;
}

.flatpickr-current-month .numInputWrapper,
.flatpickr-calendar .flatpickr-current-month input.cur-year {
  font-weight: 500;
}
.flatpickr-calendar .flatpickr-current-month input.cur-year {
  border: 1px solid transparent;
  width: 6ch;
}
.flatpickr-calendar .flatpickr-current-month input.cur-year:hover {
  background: #ffffff;
  border: 1px solid #ddd !important;
  border-right: none !important;
  color: #444;
}
.flatpickr-calendar .flatpickr-current-month .numInputWrapper:hover {
  background: #ffffff;
  color: #444;
}
.flatpickr-calendar .flatpickr-current-month .numInputWrapper span {
  right: unset;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 41px;
  max-height: 41px;
}
.flatpickr-calendar .flatpickr-current-month input.cur-year {
  padding: 0 0 0 0.7ch;
}
.flatpickr-calendar .flatpickr-current-month .numInputWrapper span.arrowUp,
.flatpickr-calendar .flatpickr-current-month .numInputWrapper span.arrowDown {
  width: 25px;
}
.flatpickr-calendar
  .flatpickr-current-month
  .numInputWrapper
  span.arrowUp::after,
.flatpickr-calendar
  .flatpickr-current-month
  .numInputWrapper
  span.arrowDown::after {
  top: 50%;
  left: 50%;
  transform: translate(-60%, -50%);
}

.flatpickr-calendar .flatpickr-time .flatpickr-time input {
  padding: 0 30px 0 0;
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowUp,
.flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowDown {
  width: 30px;
}
.flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowUp::after,
.flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowDown::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flatpickr.flatpickr-input.flatpickr-mobile {
  border: 1px solid !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-height: 32px !important;
  height: 32px !important;
  font-size: 0.9rem !important;
  border-color: #e2e8f0 !important;
  min-width: 280px !important;
  width: 100% !important;
}
