body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0c131f !important;
  background-image: radial-gradient(
    #29364e 0%,
    #242e46 30%,
    #0c131f 90%
  ) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-content: space-evenly;
  overflow: hidden;
  position: fixed !important;
}
#root {
  height: 100%;
  width: 100%;
  position: fixed !important;
  /* height: 100%; */
  width: 100%;
}
